import COMPANIES from '../../../constants/flightCompanies'
import moment from 'moment'
import _ from 'lodash'
import cityCityException from '../../cityCityException.Class'
import CheckoutFetch from '../../../repositories/checkout'
import { buildRemarks } from './helpers'
const checkoutFetch = new CheckoutFetch()

export const getAvailableFop = (checkout = {}, isAuthenticated = true) => {
  const { costfields = {}, fop } = checkout || {}
  const ccAvailable = costfields?.paymentMethods?.creditCard || false
  const fops = []
  if (isAuthenticated) {
    fops.push(costfields?.paymentMethods?.primaryPayment || 'profileCC')
  }
  if (ccAvailable || !isAuthenticated) fops.push('cc')
  const rqFops = []
  if (!fops.includes(fop)) {
    rqFops.push(fops[0])
  }
  return { fops, rqFops }
}

export default (model, state) => {
  // model is the untouched cart type flight
  // should return everything needed to complete a purchase
  const {
    fop,
    creditCard,
    publicContacts,
    netsPaymentId,
    paiwiseCheckoutId,
    ccFees,
    costFieldValues = {},
    costfields,
  } = state.checkout
  const { user, isAuthenticated, encloseCostfieldValues } = state.auth
  const itemId = model?.uniqueInd || null
  let feeAmount = 0

  if (!!itemId && !!ccFees?.[itemId] && parseFloat(ccFees[itemId]) > 0)
    feeAmount += parseFloat(ccFees[itemId])

  let userInd = 0

  const fopInfo = getAvailableFop(state.checkout, isAuthenticated)
  const FlightFops = fopInfo.fops
  const { baggage, outgoing, returning, currentFare, returnFare, seats } = model
  const { isMultiStop, trips } = model

  const returnFl = returning ? returning.original.returnTrip : []
  const outboundFl = outgoing?.original?.trip

  const isMultiTicket = returning?.original?.isMultiTicket
  const passengers = model.passengers.map((u) => {
    userInd++

    const services = []
    const servicesInfo = {}

    if (isMultiStop) {
      for (let trip of trips) {
        let baggage = trip.baggage?.[u.uniqueId]
        if (!baggage) continue

        let tripBag = baggage['general'] || baggage['carryOn']
        if (!tripBag) continue

        const fl = [...trip.outgoing.original.trip]
        const fk = getFK(fl)
        const preparedSrv = buildServicesForBooking(baggage, fk)
        servicesInfo.outbound = buildServicesTextInfo(baggage)
        services.push(...preparedSrv)
      }
    } else {
      const outboundBaggage = baggage?.outbound?.[u.uniqueId]
      const returnBaggage = baggage?.return?.[u.uniqueId]
      const isOutboundBag =
        !!outboundBaggage?.general?.services ||
        !!outboundBaggage?.carryOn?.services
      const isInboundBag =
        !!returnBaggage?.general?.services || !!returnBaggage?.carryOn?.services

      if (!!isOutboundBag) {
        const fl =
          returnFl?.length > 0 && !isMultiTicket
            ? [...outboundFl, ...returnFl]
            : [...outboundFl]
        const fk = getFK(fl)
        const preparedSrv = buildServicesForBooking(outboundBaggage, fk)
        servicesInfo.outbound = buildServicesTextInfo(outboundBaggage)
        services.push(...preparedSrv)
      }

      if (isInboundBag && returnFl?.length > 0 && isMultiTicket) {
        const fk = getFK(returnFl)
        const preparedRtSrv = buildServicesForBooking(returnBaggage, fk)
        servicesInfo.return = buildServicesTextInfo(returnBaggage)
        services.push(...preparedRtSrv)
      }
    }

    const passCfData = {
      costFieldValues: costFieldValues?.[u.uniqueId] || {},
      costfields,
    }
    return {
      ...u,
      services: services,
      servicesInfo: servicesInfo,
      locator: u.uniqueId,
      remarks: buildRemarks(passCfData, encloseCostfieldValues),
      type: u.type || 'adult',
      ind: userInd.toString(),
    }
  })

  // console.log(outgoing)
  // console.log(currentFare)

  const markupOutgoing =
    currentFare?.markupOutgoing ||
    (returning
      ? outgoing?.markupOutgoing
      : outgoing?.markupOutgoing + outgoing?.markupReturn)
  let markupReturn = null

  const flights = []
  let fareServices = null

  let exceedsPolicy = false
  let co2 = 0

  if (isMultiStop) {
    for (const [tripIndex, trip] of trips.entries()) {
      if (trip.exceedsPolicy) exceedsPolicy = true
      co2 += trip.co2

      const { resultTrip: multistopFlights, fareServices: multistopSrv } =
        prepareTrip(
          trip.outgoing.original.trip,
          trip.currentFare,
          'trip',
          passengers,
          seats,
          { multiStop: tripIndex }
        )

      flights.push(...multistopFlights)
      if (!!multistopSrv) fareServices = { multistop: multistopSrv }
    }
  }
  // throw 'Multistop not implemented'

  if (outgoing) {
    if (outgoing.exceedsPolicy) exceedsPolicy = true
    co2 += outgoing.co2

    const { resultTrip: outboundFlights, fareServices: outboundSrv } =
      prepareTrip(
        outgoing.original.trip,
        currentFare,
        'trip',
        passengers,
        seats
      )

    flights.push(...outboundFlights)
    if (!!outboundSrv) fareServices = { outbound: outboundSrv }
  }

  if (returning) {
    if (returning.exceedsPolicy) exceedsPolicy = true
    co2 += returning.co2

    const tmpFF = isMultiTicket ? returnFare : currentFare
    const { resultTrip: returnFlights, fareServices: returnSrv } = prepareTrip(
      returning.original.returnTrip,
      tmpFF,
      'returnTrip',
      passengers,
      seats,
      { groupNumber: 2 }
    )

    markupReturn = returnFare?.markupReturn || returning.markupReturn

    if (!!returnSrv) {
      if (!fareServices) fareServices = {}
      fareServices.return = returnSrv
    }

    flights.push(...returnFlights)
  }

  const flightPaymentData = {
    fop: FlightFops.includes(fop) ? fop : FlightFops[0],
  }

  if (netsPaymentId || paiwiseCheckoutId) {
    // For Nets payments, use "invoice", which translates to NONREF.
    flightPaymentData.fop = 'invoice'
  } else if (flightPaymentData.fop === 'cc') {
    if (
      !creditCard?.number ||
      !creditCard?.expire ||
      !creditCard.cvv ||
      !creditCard.ccCode
    ) {
      throw new cityCityException({ message: 'Invalid flight credit card' })
    }

    flightPaymentData['ccInfo'] = {
      vendorCode: creditCard?.ccCode || null,
      ccNumber: creditCard.number.replace(/\s+/g, ''),
      cvv: creditCard.cvv,
      expire: creditCard.expire
        ? creditCard.expire.replace('/', '').replace(/\s+/g, '')
        : '',
    }
  }

  return {
    flights: flights,
    fareServices,
    passengers: passengers,
    email: isAuthenticated ? user.email : publicContacts.email,
    mobile: isAuthenticated ? user.mobile : publicContacts.phone,
    phone: isAuthenticated ? user.mobile : publicContacts.phone,
    flightPaymentData: flightPaymentData,
    ticketsSeparate: isMultiTicket,
    farePrice: model.farePrice,
    obFee: feeAmount,
    totalPrice: model.totalPrice + feeAmount,
    expectedPrice:
      model.totalPrice -
      Math.floor(markupOutgoing) -
      Math.floor(markupReturn) +
      feeAmount,
    ffBags: model?.ffBags || null,
    co2,
    markupOutgoing,
    markupReturn,
    exceedsPolicy,
    isMultiStop,
  }
}

const prepareTrip = (
  trip,
  ff,
  type = 'trip',
  passengers = null,
  seats = null,
  extParams = {}
) => {
  let fareServices = null
  const resultTrip = trip.map((t) => {
    t.type = type
    const resultTrip = { ...t, ...extParams }
    const tmpFF = ff?.byFlights?.[t.uniqueInd]
    if (tmpFF) {
      if (!tmpFF?.isDynamic) resultTrip.fareFamily = tmpFF.ffName
      resultTrip.fareBasis = tmpFF.ffFareBasis
      resultTrip.ticketLabel = tmpFF.label || 'Standard'
      resultTrip.bookingclass = tmpFF.bookingClass

      resultTrip.markup = tmpFF.markup
    }

    if (passengers && seats) {
      resultTrip.seats = {}
      passengers.forEach((p) => {
        const uSeat = seats.find(
          (s) => s.flightKey === t.uniqueInd && p.uniqueId === s.userId
        )
        // console.log(p.uniqueId)
        // console.log(uSeat)
        if (uSeat) {
          resultTrip.seats[p.ind] = {
            seatName: uSeat.seat,
            seatPrice: uSeat.price,
            seatInfo: uSeat.seatInfo,
          }
        }
      })
    }
    if (
      tmpFF?.ffServ?.length &&
      (!fareServices || !fareServices[tmpFF.ffName])
    ) {
      if (!fareServices) fareServices = {}
      const chaSrvs = tmpFF?.serviceCodes?.CHA
      fareServices[tmpFF.ffName] = tmpFF.ffServ.map((s) => {
        let isCh = false
        if (chaSrvs) {
          for (let tmpType in chaSrvs) {
            const tmpSrvs = chaSrvs?.[tmpType]
            if (tmpSrvs?.length && tmpSrvs.includes(s.code)) {
              isCh = true
              break
            }
          }
        }
        return { type: s.type, text: s.text, charge: isCh }
      })
    }
    resultTrip.to_name = resultTrip.to //temporary
    resultTrip.from_name = resultTrip.from //temporary
    resultTrip.date = moment(resultTrip.departure).format('ll')
    resultTrip.toTime = moment(resultTrip.arrival).format('HH:mm')
    resultTrip.fromTime = moment(resultTrip.departure).format('HH:mm')
    resultTrip.companyName = COMPANIES[resultTrip.airline]
      ? COMPANIES[resultTrip.airline].label
      : resultTrip.airline
    resultTrip.operatingCarrierName =
      COMPANIES[resultTrip.operatingCarrier] ?? resultTrip.operatingCarrier
    resultTrip.isReturnTrip = type === 'returnTrip'

    return resultTrip
  })

  return { resultTrip, fareServices }
}

export const getCCFee = async (state) => {
  const {
    items,
    fop,
    creditCard: { number, ccCode },
    costfields,
  } = state

  const allowedFops = ['profileCC', 'agreement'] //fop "cc" checked separately
  if ((fop !== 'cc' || !number || !ccCode) && !allowedFops.includes(fop))
    return {}

  const flightAgreementMethod =
    costfields?.paymentMethods?.agreementMethods?.['flight']

  const itemsFee = {}
  const promises = []

  items.forEach((item) => {
    if (item.type !== 'Flight') return

    // For NONREF (invoice) payment method, skip CC fees.
    if (fop === 'agreement' && flightAgreementMethod === 'invoice') return

    promises.push(
      (async (itm) => {
        const {
          outgoing,
          returning,
          passengers,
          uniqueInd,
          currentFare,
          returnFare,
        } = itm
        const isMultiTicket = returning?.original?.isMultiTicket

        const trip = prepareTrip(
          outgoing.original.trip,
          currentFare,
          'trip'
        ).resultTrip
        let returnTip = null
        if (returning) {
          const tmpFF = isMultiTicket ? returnFare : currentFare
          if (!isMultiTicket) {
            trip.push(
              ...prepareTrip(
                returning.original.returnTrip,
                tmpFF,
                'returnTrip',
                null,
                null,
                { groupNumber: isMultiTicket ? 2 : null }
              ).resultTrip
            )
          } else {
            returnTip = prepareTrip(
              returning.original.returnTrip,
              tmpFF,
              'returnTrip',
              null,
              null,
              { groupNumber: isMultiTicket ? 2 : null }
            ).resultTrip
          }
        }

        const cc = fop === 'cc' ? { number, ccCode } : null
        const requests = []
        requests.push(checkoutFetch.fetchCCFee({ trip, passengers }, cc, fop))
        if (returnTip) {
          requests.push(
            checkoutFetch.fetchCCFee({ trip: returnTip, passengers }, cc, fop)
          )
        }
        let fee = 0
        const rqResults = await Promise.allSettled(requests)
        rqResults.forEach((rqRes) => {
          if (rqRes.status !== 'fulfilled') return
          const data = rqRes?.value
          const fees =
            data?.data?.mainGroup?.pricingGroupLevelGroup?.fareInfoGroup
              ?.fareAmount?.otherMonetaryDetails
          if (!fees || fees.length <= 0) return

          const obData = _.find(fees, (d) => d.typeQualifier === 'OB')
          if (obData?.amount && obData.amount > 0) {
            fee += Number(obData.amount * passengers.length)
          }
        })

        return { uniqueInd, fee }
      })(item)
    )
  })

  if (promises.length) {
    const results = await Promise.allSettled(promises)
    results.forEach((result) => {
      if (
        result.status === 'fulfilled' &&
        result.value?.fee &&
        result.value?.uniqueInd
      ) {
        itemsFee[result.value.uniqueInd] = result.value?.fee
      }
    })
  }

  return itemsFee
}

const buildServicesForBooking = (gaggage, fk) => {
  const preparedSrv = []
  const bagTypes = ['general', 'carryOn']
  bagTypes.forEach((bt) => {
    const singleService = gaggage?.[bt]
    if (!singleService) return false
    if (!singleService?.services?.length)
      throw new cityCityException({
        message: 'Missed service for some flights',
      })
    let servFN = []
    singleService.services.forEach((bSrv) => {
      servFN = [...servFN, ...bSrv.flightKeys]
      preparedSrv.push({ ...bSrv })
    })

    const FNdif = fk.filter((num) => !servFN.includes(num))
    if (FNdif?.length > 0) {
      throw new cityCityException({
        message: 'Missed service for some flights',
      })
    }
  })

  return preparedSrv
}
const buildServicesTextInfo = (gaggage) => {
  const preparedSrv = []
  const bagTypes = ['general', 'carryOn']
  bagTypes.forEach((bt) => {
    const isCO = bt === 'carryOn'
    const bag = gaggage?.[bt] || null
    if (!bag || typeof (bag) !== 'object') return false;
    const pt = bag?.pricingType || 'PPR'
    preparedSrv.push({ text: bag.description, pricingType: pt, count: pt !== 'PPK' ? bag.count : bag.w, type: isCO ? 'COBAG' : 'USR_BAG' })
  })

  return preparedSrv
}

const getFK = (fl) => {
  const fn = []
  fl.forEach((f) => {
    fn.push(f.uniqueInd)
  })

  return fn
}
