import React, { memo, useEffect, useState } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Box, CircularProgress } from '@material-ui/core'

//  components
import Landing from '../pages/landing/landing'
import Flights from '../pages/flights/flights'
import FlightsMultiCity from '../pages/flights/flightsMultiCity'
import Cars from '../pages/cars/cars'
import CarsSearchResults from '../pages/cars/carsSearchResults'
import CarsDetails from '../pages/cars/carDetails'
import AddPublicDataPage from '../pages/addPublicDataPage'
import Hotels from '../pages/hotels/hotels'

import ChooseSeats from '../pages/trains/chooseSeats'
import Trains from '../pages/trains/trains'
import MyTrips from '../pages/my-trips/my-trips'
import TripsCalendars from '../pages/my-trips/tripsCalendars'
import Profile from '../pages/profile/profile'
import Login from '../pages/auth/login'
import ActivateBookingLink from '../pages/auth/activateBookingLink'
import EmailBookingApprove from '../pages/automateBooking/EmailBookingApprove'
import PendingBookingApprove from '../pages/pendingBooking/PendingBookingApprove'
import EmailBookingChooseTrip from '../pages/automateBooking/EmailBookingChooseTrip'
import Register from '../pages/auth/register'
import ForgotPassword from '../pages/auth/forgot-password'
import Checkout from '../pages/checkout/checkout'
import BookingSuccess from '../pages/checkout/bookingSuccess'
import CheckIn from '../pages/checkIn/checkIn'
// hoc
import PrivateRoute from './privateRoute'
import SingleHotel from '../pages/hotels/_single'
import FlightDetails from '../pages/flightSearchResult/details'
import Middlestep from '../pages/middlestep/middlestep'
import OutgoingFlight from '../pages/flightSearchResult/_outgoing'
import ReturningFlight from '../pages/flightSearchResult/_returning'
import FlightResultsMultiCity from '../pages/flightSearchResult/multi'
import OutgoingSearch from '../pages/trains/_outgoingSearch'
import ReturnSearch from '../pages/trains/_returnSearch'
import SearchHotel from '../pages/hotels/_search'
import BookingConfirmation from '../pages/bookingConfirmation/bookingConfirmation'
import { CompaniesContext } from '../components/flights/filter/companiesContext'
import RootWrapper from '../containers/route/rootWrapper'
import ActivateUser from '../pages/auth/activateUser'
import ResetPassword from '../pages/auth/resetPassword'
import { makeStyles } from '@material-ui/core/styles'
import Custom from '../pages/custom/custom'
import addTrainPublicData from '../pages/trains/addTrainPublicData'
import Iframe from '../pages/iframe'
import { setIsIframe } from '../store/customization/customizationAction'
import isIframed from '../utils/isIframed'
import PolicyDocument from '../pages/profile/_policyDocument'
import JoinBookingLink from '../pages/auth/joinBookingLink'
import GroupTravels from '../pages/groupTravels/groupTravels'
import OidcRedirect from '../pages/oidc/oidcRedirect'
import OidcCallback from '../pages/oidc/oidcCallback'
import OidcLogout from '../pages/oidc/oidcLogout'

const useStyles = makeStyles((theme) => ({
  fogLightBlock: {
    position: 'fixed',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: isIframed ? 'transparent' : 'rgba(0,0,0,0.25)',
    color: 'white',
    fontSize: '20px',
    fontWeight: '600',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
}))

export const routes = [
  //Public Routes
  //"footer" - Parameter that configures the display of the footer on pages
  //"hideHeader" - Hide header for route
  //"hideForAuthType" - Hide routes for auth type
  {
    path: '/',
    name: 'home',
    component: Landing,
    exact: true,
    publicity: 'public',
  },
  {
    path: '/log-in',
    name: 'log-in',
    component: Login,
    publicity: 'public',
    authRoute: true,
  },
  {
    path: '/register',
    component: Register,
    publicity: 'public',
    hideHeader: true,
    authRoute: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    publicity: 'public',
    authRoute: true,
  },
  {
    path: '/forgot',
    component: ResetPassword,
    publicity: 'public',
    authRoute: true,
  },
  {
    path: '/activate',
    component: ActivateUser,
    publicity: 'public',
    authRoute: true,
  },

  {
    path: '/abl/join/:identifier?',
    component: JoinBookingLink,
    publicity: 'public',
    hideHeader: true,
    hideHeaderContent: true,
    authRoute: true,
  },

  {
    path: '/group-travels',
    component: GroupTravels,
    publicity: 'public',
    hideHeader: false,
  },

  {
    path: '/activate-booking-link/:jwt?',
    component: ActivateBookingLink,
    publicity: 'public',
    hideHeader: false,
    hideHeaderContent: true,
    authRoute: true,
  },
  {
    path: '/abl/:jwt?',
    component: ActivateBookingLink,
    publicity: 'public',
    hideHeader: false,
    hideHeaderContent: true,
    authRoute: true,
  },

  {
    path: '/pending-booking/:act/:jwt',
    component: PendingBookingApprove,
    publicity: 'public',
    hideHeader: true,
    hideHeaderContent: true,
    authRoute: false,
  },

  {
    path: '/automate-booking/:act/:jwt',
    component: EmailBookingChooseTrip,
    publicity: 'public',
    hideHeader: true,
    hideHeaderContent: true,
    authRoute: false,
  },

  // Protected Routes
  {
    path: '/flights',
    name: 'search flights',
    exact: true,
    component: Flights,
    publicity: 'both',
    footer: true,
    routeGroup: 'flightSearch',
  },
  {
    path: '/flights-multicity',
    name: 'search flights',
    exact: true,
    component: FlightsMultiCity,
    publicity: 'both',
    footer: true,
    routeGroup: 'flightSearch',
  },
  {
    path: '/flights/add-public-data',
    name: 'add public data',
    exact: true,
    component: AddPublicDataPage,
    publicity: 'both',
    hideHeader: true,
    routeGroup: 'flightSearch',
  },
  {
    path: '/flights/outgoing/:from/:to/:dep/:arr/:adult/:child/:infant/:isOneWay/:initialTicketClass',
    name: 'select trip',
    component: OutgoingFlight,
    publicity: 'both',
    routeGroup: 'flightSearch',
  },

  {
    path: '/flights/returning/:from/:to/:dep/:arr/:adult/:child/:infant/:initialTicketClass',
    name: 'select trip',
    component: ReturningFlight,
    publicity: 'both',
    routeGroup: 'flightSearch',
  },

  {
    path: '/flights/multi/:from/:to/:dep/:adult/:child/:infant/:initialTicketClass/:tripNumber',
    name: 'select trip',
    component: FlightResultsMultiCity,
    publicity: 'both',
    routeGroup: 'flightSearch',
  },

  {
    path: '/flights/details',
    name: 'ticket selection',
    component: FlightDetails,
    publicity: 'both',
    routeGroup: 'flightSearch',
  },

  {
    path: '/checkIn',
    name: 'check in',
    component: CheckIn,
    publicity: 'both',
    footer: true,
  },

  // cars
  {
    path: '/cars',
    name: 'search cars',
    exact: true,
    component: Cars,
    publicity: 'both',
    footer: true,
  },

  {
    path: '/cars/search',
    name: 'cars search results',
    exact: true,
    component: CarsSearchResults,
    publicity: 'both',
    footer: true,
  },

  {
    path: '/cars/add-middlestep',
    name: 'cars search results',
    exact: true,
    component: CarsSearchResults,
    publicity: 'both',
    footer: true,
    compParams: { isMiddlestep: true },
    hideHeader: true,
  },

  {
    path: '/cars/details',
    name: 'selected car details',
    exact: true,
    component: CarsDetails,
    publicity: 'both',
    footer: true,
  },

  // hotels
  {
    path: '/hotels',
    name: 'search hotel',
    component: Hotels,
    exact: true,
    publicity: 'both',
    footer: true,
  },
  {
    path: '/hotels/search/:countryCode/:lat/:lng/:checkIn/:checkOut/:roomCount/:guests',
    name: 'select hotel',
    component: SearchHotel,
    publicity: 'both',
  },
  {
    path: '/hotels/details/:countryCode/:cityCode/:hotelCode/:checkIn/:checkOut/:guests/:roomsCount',
    component: SingleHotel,
    publicity: 'both',
  },

  // Train
  {
    path: '/trains/outgoing/:from/:to/:dep/:arr/:isTwoWay/:depTimeOutbound?/:depTimeInbound?',
    name: 'select trip',
    component: OutgoingSearch,
    publicity: 'both',
    routeGroup: 'trainSearch',
  },

  {
    path: '/trains/returning/:from/:to/:dep/:arr/:isTwoWay/:depTimeOutbound?/:depTimeInbound?',
    name: 'select trip',
    component: ReturnSearch,
    publicity: 'both',
    routeGroup: 'trainSearch',
  },

  {
    path: '/trains/add-public-data',
    name: 'add public data',
    exact: true,
    component: addTrainPublicData,
    publicity: 'both',
    hideHeader: true,
    routeGroup: 'trainSearch',
  },

  {
    path: '/trains/choose-seats',
    name: 'ticket selection',
    component: ChooseSeats,
    publicity: 'both',
    routeGroup: 'trainSearch',
  },
  {
    path: '/trains',
    name: 'search train',
    component: Trains,
    exact: true,
    publicity: 'both',
    footer: true,
    routeGroup: 'trainSearch',
  },

  {
    path: '/middlestep/:type/:countryCode/:lat/:lng/:checkIn/:checkOut/:roomCount/:guests',
    name: 'middlestep',
    component: Middlestep,
    exact: false,
    publicity: 'both',
  },
  {
    path: '/my-trips',
    component: MyTrips,
    publicity: 'private',
    hideForAuthType: ['agent'],
  },
  {
    path: '/policy',
    component: PolicyDocument,
    publicity: 'private',
    compParams: { isStandalone: true },
  },
  {
    path: '/company-trips-calendar',
    component: TripsCalendars,
    publicity: 'private',
  },
  {
    path: '/profile',
    component: Profile,
    publicity: 'private',
    hideForBookLink: true,
    hideForAuthType: ['agent'],
  },
  {
    path: '/checkout',
    name: 'book trip',
    component: Checkout,
    publicity: 'both',
  },
  {
    path: '/book-result',
    name: 'Book Result',
    component: BookingSuccess,
    publicity: 'public',
    ignoreAuth: true,
  },
  {
    path: '/booking-confirmation',
    component: BookingConfirmation,
    publicity: 'both',
  },
  {
    path: '/iframe/:type?',
    name: 'iframe',
    component: Iframe,
    publicity: 'both',
    hideHeader: true,
  },

  {
    path: '/oidc/:companyId',
    component: OidcRedirect,
    exact: true,
    publicity: 'public',
    hideHeader: true,
  },

  {
    path: '/oidc/:companyId/login',
    component: OidcCallback,
    exact: true,
    publicity: 'public',
    hideHeader: true,
  },

  {
    path: '/oidc/:companyId/logout',
    component: OidcLogout,
    exact: true,
    publicity: 'public',
    hideHeader: true,
  },
]

const Routes = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { publicityLoading, publicity, authType, isAuthenticated, user } =
    useSelector((state) => state.auth)
  const [companies, setCompanies] = useState([])
  const { isLoading: customizationLoading, pages } = useSelector(
    (state) => state.customization
  )
  const location = useLocation()

  useEffect(() => {
    dispatch(setIsIframe(false))
  }, [])

  useEffect(() => {
    if (window.parent !== window) {
      setTimeout(() => {
        window.parent.postMessage(
          {
            type: 'path-changed',
            path: location.pathname,
          },
          '*'
        )
      }, 100)
    }
  }, [location])

  if (publicityLoading || customizationLoading) {
    return (
      <Box>
        <Box className={classes.fogLightBlock}>
          <CircularProgress />
        </Box>
      </Box>
    )
  }
  let hasCMSLanding = false
  const CMSRoutes =
    pages?.map((page, index) => {
      const path = Object.keys(page.content).map(
        (lang) => `/c/${page.content[lang].slug}`
      )
      if (!!page.isStartPage) hasCMSLanding = true
      return {
        path: path,
        component: Custom,
        publicity: 'both',
        footer: false,
        exact: true,
        key: `${path}-${index}`,
        compParams: { pageId: page?.['_id'] },
        isCustomLanding: !!page.isStartPage,
      }
    }) || []

  const renderRoutes = [...routes, ...CMSRoutes].map((route, index) => {
    const isPublicKeys = ['public']
    let routePublicity = route.publicity

    if (
      route?.hideForAuthType?.length > 0 &&
      route.hideForAuthType.includes(authType)
    ) {
      return null
    }
    //make /flights and /hotels routes private if cms landing page exists
    if (
      hasCMSLanding &&
      (route.path === '/flights' || route.path === '/hotels')
    ) {
      //      routePublicity = 'private'
    }

    if (publicity === 'both' || publicity === 'public')
      isPublicKeys.push('both')
    if (
      publicity === 'public' &&
      (routePublicity === 'private' || route.authRoute)
    )
      return null

    // remove "my trip" page if no user or page disabled
    if (route.path === '/my-trips' && (!user || !!user?.hideMyTrips))
      return null

    if (!!route.hideForBookLink && !!user?.isBookingLink) return null

    const alternatePath = '/book-trip' + route.path

    const routeKey = route.key || route.path
    if (isPublicKeys.includes(routePublicity)) {
      return (
        <Route
          key={routeKey}
          path={[route.path, alternatePath]}
          exact={route.exact}
          render={(props) => {
            //A wrapper for components, in which elements common to all routes can be added (for example, footer)
            return (
              <RootWrapper
                key={routeKey + '-inner'}
                componentParams={{ ...props, ...(route.compParams || {}) }}
                routeParams={{ ...route }}
              />
            )
          }}
        />
      )
    }

    return (
      <PrivateRoute
        key={routeKey + '-private'}
        path={route.path}
        exact={route.exact}
        routeParams={{ ...route }}
        isAuthenticated={isAuthenticated}
      />
    )
  })

  return (
    <CompaniesContext.Provider
      key={'route-outer-wrapper'}
      value={[companies, setCompanies]}
    >
      <Switch key={'route-switch-wrapper'}>
        {renderRoutes}
        <Redirect to="/" />
      </Switch>
    </CompaniesContext.Provider>
  )
}

export default memo(Routes)
